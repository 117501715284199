<template>
  <v-container>
   <div class="my-5 d-flex align-start">
     <v-btn
      icon
      color="accent darken-3"
      class="align-self-center"
      link
      @click="backtoPrevRoute()"
     >
       <v-icon left>fa-solid fa-chevron-left</v-icon>
     </v-btn>
     <h2 class="primary--text main-heading">Back to Services</h2>
     <v-divider class="mx-4" inset vertical></v-divider>
   </div>
   <v-row class="my-10">
    <v-col
     v-if="getServiceByIdStatus_Idle || getServiceByIdStatus_Pending"
     cols="12"
    >
       <v-skeleton-loader
         type="card-heading, list-item-three-line, image, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, actions"
       >
       </v-skeleton-loader>
    </v-col>
    <v-col
     v-else
     cols="12"
    >
      <base-card card-height="750">
        <template #content>
            <div 
             v-if="userIsAdmin"
             class="d-flex justify-end ma-0 pa-0"
            >
              <v-icon normal color="primary" @click="editService()">fa fa-edit</v-icon>
              <v-icon normal right color="error" @click="confirmDelete()">fa fa-trash</v-icon>
            </div>
            <div class="d-flex flex-column text-center align-center justify-center">
                <h1 class="main-heading">{{ service.title }}</h1>
                <div class="divider-container mb-10" />
                <p
                  v-if="service.description" 
                  class="mb-5"
                  v-html="service.description"
                />
                <p v-else class="mb-5">Description is not Provided</p>
                <iframe
                  v-if="service.youtube_url != null"
                  width="100%"
                  height="450px"
                  loading="eager" 
                  :src="youtubeURL"
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen 
                />
                <div
                 v-else
                 class="mt-10 d-flex align-center flex-column justify-center"
                >
                  <!-- <p class="mb-3">Video url is not provided..</p> -->
                  <v-img
                    src="../../assets/noVideo.jpg"
                    width="400px"
                    height="350px"
                    contain
                  >
                  </v-img>
                </div>
            </div>
        </template>
      </base-card>
      <div class="my-10">
        <div class="hidden-sm-and-down">
          <div class="d-flex align-center">
            <v-icon normal left color="purple">fa fa-download</v-icon>
            <h1 class="main-heading">{{ $t(`Admin.service_box.service_details.serviceMaterial`) }}</h1>
          <span class="ml-2 font-weight-thin text-caption">You can download the pdf for more information</span>
        </div>
        </div>
        <div class="hidden-md-and-up">
          <div class="d-flex flex-column align-center">
            <v-icon normal left color="purple">fa fa-download</v-icon>
            <h1 class="main-heading">{{ $t(`Admin.service_box.service_details.serviceMaterial`) }}</h1>
            <span class="mb-10 ml-2 font-weight-thin text-caption">You can download the pdf for more information</span>
          </div>
        </div>
        <div style="background: #eeeeeecf;">
            <v-row class="px-10 py-5">
                <v-col class="d-flex flex-column align-start gap">
                    <p class="mt-3">
                      Presentantion:
                    </p>
                    <p>Additional url:</p>
                </v-col>
                <v-col
                 class="d-flex flex-column align-end gap">
                  <div v-if="service.file != null">
                    <v-btn
                      v-for="url in service.file"
                      :key="url.id" 
                      depressed
                      class="mt-2 purple secondary--text button-hover"
                      :href="url.url"
                      target="_blank"
                      download
                     >
                     <v-icon x-small left>fa fa-folder</v-icon>
                      {{ $t(`Admin.service_box.service_details.download`) }}
                     </v-btn>
                  </div>
                  <div
                    v-else
                    class="d-flex flex-column align-end gap"
                  >
                    <p class="mt-3">Not Provided</p>
                  </div>
                  <a
                   v-if="service.additional_url"
                   target="_blank"
                   :href="hasCompletedURL ? service.additional_url : null"
                   :class="`black--text text-decoration-none disable-url-cursor ${hasCompletedURL ? 'additional-url-hover' : ''}`"
                  >
                    {{ service.additional_url }}
                  </a>
                  <p v-else>Not Provided</p>
                </v-col>
            </v-row>
        </div>
      </div>
      <v-row class="my-10">
        <v-col cols="12">
            <div class="d-flex align-center">
                <v-icon normal left color="purple">fa fa-info</v-icon>
                <h1 class="main-heading">{{ $t(`Admin.service_box.service_details.details`) }}</h1>
            </div>
            <base-card card-height="200">
                <template #content>
                    <v-row class="hidden-sm-and-down">
                        <v-col class="d-flex flex-column align-start gap">
                            <p>Availability:</p>
                            <p>Language(s):</p>
                            <p>Proficiency:</p>
                        </v-col>
                        <v-col class="d-flex flex-column align-end gap">
                            <p :class="`font-weight-normal text-h6 ${availabilityColor}--text`">{{ service.availability || 'Not Provided' }} </p>
                            <div
                             v-if="service.languages != null"
                             class="d-flex flex-wrap"
                            >
                             <p
                              v-for="language in service.languages"
                              :key="language.id"
                              class="font-weight-light ml-1"
                             >
                               {{ language.name.charAt(0).toUpperCase() + language.name.slice(1) }}
                             </p>
                             <!-- <v-chip-group
                              center-active
                              show-arrows
                             >
                                <v-chip
                                 v-for="language in service.languages"
                                 :key="language.id"
                                 min-width="50"
                                 max-width="150"
                                 class="font-weight-light"
                                >
                                    {{ language.name.charAt(0).toUpperCase() + language.name.slice(1) }}
                                </v-chip>
                             </v-chip-group> -->
                            </div>
                            <div v-else>
                              <p>Not Provided</p>
                            </div>
                            <p :class="`font-weight-light ${proficiencyColor}--text`">{{ service.proficiency_level || 'Not Provided' }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="hidden-md-and-up">
                        <v-col class="d-flex flex-column align-center">
                            <div class="d-flex align-center">
                              <p>Availability:</p>
                              <p :class="`ml-2 font-weight-normal text-h6 ${availabilityColor}--text`">{{ service.availability || 'Not Provided' }} </p>
                            </div>
                            <v-divider class="px-5 my-3 purple"></v-divider>
                            <p>Languages(s):</p>
                            <v-chip-group
                              v-if="service.languages != null"
                              center-active
                              show-arrows
                             >
                                <v-chip
                                 v-for="language in service.languages"
                                 :key="language.id"
                                 min-width="50"
                                 max-width="150"
                                 class="font-weight-light"
                                >
                                    {{ language.name.charAt(0).toUpperCase() + language.name.slice(1) }}
                                </v-chip>
                             </v-chip-group>
                             <span
                              v-else
                              class="font-weight-light"
                             >
                              Not Provided
                             </span>
                             <v-divider class="px-5 my-3 purple"></v-divider>
                             <p>Proficiency:</p>
                             <p :class="`font-weight-light ${proficiencyColor}--text`">{{ service.proficiency_level || 'Not Provided' }}</p>
                        </v-col>
                    </v-row>
                </template>
            </base-card>
                <p class="text-center error--text mt-5">
                    <span class="font-weight-light">Updated on: </span>
                    <span class="black--text font-weight-light">{{ new Date(service.updated_at).toDateString() }}</span>
                </p>
         </v-col>
       </v-row>
     </v-col>
   </v-row>
  <confirm-modal
   :open="openModal"
   :close.sync="openModal"
  >
    <template #content>
     <span class="modal-content">
       You are going to delete this service. Take a look, this action cannot be reversed.
     </span>
    </template>
    <template #action>
      <v-btn
        depressed
        dense
        color="error lighten-1"
        class="mr-2"
        :loading="false"
        @click="deleteService()"
      >
        Delete
      </v-btn>
    </template>
  </confirm-modal>
 </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { withAsync } from "../../helpers/withAsync.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { getToolBoxService, deleteToolBoxService } from "@/api/adminApi.js"
import BaseCard from '../../components/base/BaseCard.vue';
import ConfirmModal from "@/components/ConfirmModal";


export default {
    name: "ToolBoxCardDetails",
    props: {
        id: {
            type: [Number, String],
            required: true,
        }
    },
    components: {
        BaseCard,
        ConfirmModal,
    },
    data() {
        return {
            getServiceByIdStatus: apiStatus.Idle,
            deleteServiceByIdStatus: apiStatus.Idle,
            service: [],
            youtubeURL: "",
            openModal: false,
        }
    },
    computed: {
        ...apiStatusComputed(["getServiceByIdStatus", "deleteServiceByIdStatus"]),
        ...mapGetters({
            userIsAdmin: "auth/userIsAdmin",
        }),
        proficiencyColor() {
            if (this.service.proficiency_level == 'Advanced') {
                return 'error'
            }
            if (this.service.proficiency_level == 'Intermediate') {
                return 'warning'
            }
            return 'black'
        },
        availabilityColor() {
          if(this.service.availability == 'Available') {
            return 'primary'
          }
          if(this.service.availability == 'Not Available') {
            return 'error'
          }
          return 'black'
        },
        hasCompletedURL() {
          if(this.service.additional_url.includes('https://')) {
            return true;
          }
          return false;
        },
    },
    methods: {
      backtoPrevRoute() {
        this.$router.go(-1);
      },
        formatUrl(url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/; 
            const mather = url.match(regExp);

            if (mather && mather[2].length == 11) {
                return mather[2];
            } else {
                return null;
            }
        },
        async fetchServiceById() {
            this.getServiceByIdStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getToolBoxService, this.id);


            if (error) {
                this.getServiceByIdStatus = apiStatus.Error
                return;
            }

            this.getServiceByIdStatus = apiStatus.Success;
                
            if(response.data.youtube_url != null) {
              const urlID = this.formatUrl(response.data.youtube_url);
              this.youtubeURL = `https://www.youtube.com/embed/${urlID}`;
            } else {
              this.youtubeURL = 'Not Provided'
            }
            this.service = response.data;
        },
        editService() {
            this.$router.push({name: 'Create - Edit Toolbox', params: {id: this.id} });
        },
        confirmDelete() {
            this.openModal = true;
        },
        async deleteService() {
            this.deleteServiceByIdStatus = apiStatus.Pending;

            const { response, error } = await withAsync(deleteToolBoxService, this.id);

            if (error) {
                this.deleteServiceByIdStatus = apiStatus.Error
                return;
            }

            this.deleteServiceByIdStatus = apiStatus.Success;
            this.$router.push({ name: 'Toolbox'});
        } 
    },
    created() {
        this.fetchServiceById();
    }

}
</script>

<style>
a {
  color: #33CC33 !important;
}
a:hover {
  color: #c35272 !important;
}
.divider-container {
    width: 100px;
    border-top: 3px solid #33CC33;
}
.gap {
    gap: 1rem;
}
.button-hover:hover {
    background: #33CC33 !important;
    transition: 0.3s ease-in;
}
.disable-url-cursor {
  cursor: auto;
}
.additional-url-hover:hover {
  color: #c35272 !important;
  cursor: pointer;
}
</style>    